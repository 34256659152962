__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import {onLoading} from "./_modules/website";
import {loadPage} from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import $ from 'jquery';


document.addEventListener("DOMContentLoaded", () => {
    onLoading();
    loadPage();

    /* var fullPageInstance = new fullpage('#myFullpage', {
       navigation: true,
       sectionsColor:['#ff5f45', '#0798ec', '#fc6c7c', 'grey'],
       licenseKey: 'gplv3-license',
       scrollingSpeed: 400,
       css3: true,
     });*/


    /*  console.log(fullPageInstance);*/

    const scrollContainer = document.querySelector('.main');
    const menu = document.getElementById('menu');

    scrollContainer.addEventListener('scroll', () => {
        if (scrollContainer.scrollTop > 0) {
            menu.classList.add('slide-out');
            return false;
        } else {
            menu.classList.remove('slide-out');
        }

    });


    const scrollTrigger = document.querySelector('.scroll-down-arrow');

    scrollTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        $("main").animate({ scrollTop: $('#about').offset().top }, 800);
    });

});